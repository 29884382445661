$blueGreen: #1ba8a3;
$greyText: #4f4f4f;
$orange: #f15e3f;
$yellow: #fec56f;
$white: #fff;
$black: #000;
$greyBG: #f2f2f2;
/*font-family: 'Merriweather Sans', sans-serif;
font-family: 'Varela Round', sans-serif;*/

body {
	margin: 0;
	padding: 0;
	color: #333;
	font-size: 16px;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: 'Varela Round', sans-serif;	
}

.preloader {
	width: 100%;
	height: 50%;
	padding:20% 20%;
	box-sizing: border-box;
	text-align: center;

	h3 {
		font-size:2em;
	}        
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Merriweather Sans', sans-serif;
	color: $orange;
}

h1 {
	font-size: 2em;
	margin:15px 0;
}

h2 {
	font-size: 1.8em;
	margin:15px 0;
}

h4 {
	font-size: 1.2em;
	margin:15px 0;
}

p {
	line-height:1.3em;
	margin-bottom:15px;
}

a {
	font-family: 'Varela Round', sans-serif;		
}


.button {
	padding: 5px 15px;
	background: $orange;
	color: $white;
	text-decoration: none;
	font-family: 'Varela Round', sans-serif;
	font-weight: bold;
	font-size: 16px;
	transition: background .8s;	

	&:hover {
		background: $yellow;		
	}

	&:disabled {		
		background: lighten($greyText, 20%);
	}
}

.orange {
	color: $orange;
}

.red {
	color:red;
}

/* animation */

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.thankYouMessageHolder {
	width:100%;
	padding:15px;
	text-align:center;		
	box-sizing:border-box;

	h3 {
		font-size: 1.5em;
		margin:15px 0 12px 0;			
	}
}

.preloaderDiv {
	padding:15px 0 35px;
	font-size:1.3em;
	color:$orange;	

	svg {
		fill:$orange;
		animation: spinningPreloader 1s infinite linear;
	}
}

@keyframes spinningPreloader {
	from {
		transform: scale(1) rotate(0deg);
	} to {
		transform: scale(1) rotate(360deg);
	}
}

/* start header CSS */

.signInBar {
	display: block;
	width:100%;
  padding: 5px 50px;
	background:$blueGreen;
  box-sizing: border-box;

  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;      

    li {
      color:$white;
      padding:5px 0;

      a {
        color:$white;
        text-decoration: none;
        font-weight: bold;
        transition: color .8s;

        &:hover {
          color: $yellow;
        }

        &.selected {
          color: $yellow;
        }
      }
    }
  }       

  .svg {
    height:1.3em;
    padding-left:10px;
  }
}

.navbar {
	display:flex;
  	justify-content: space-between;
  	align-items: center;
	width:100%;
	background: $orange;
	padding: 5px 50px;
  	box-sizing: border-box;

  .logoHolder {
    
    img {
      image-rendering: auto; 
      width:auto;
      height:50px;
    }
  }

  ul {
    display:flex;

    li {
      display:inline;
      padding: 0 10px;
      
      &:last-child {
        padding-right:0;
      }

      a {
        color:$white;
        font-weight: bold;
        text-decoration: none;
        transition: color .8s;
        
        &:hover {
          color: $yellow;
        }

        &.selected {
          color: $yellow;
        }
      }
    }
  }
}

@media (max-width:845px) {
  .signInBar {
    padding: 5px 25px;
  }

  .navIcon {
    cursor:pointer;
  }  

  .navbar {
    position:relative;
    padding: 5px 25px;    

    ul {
      position:absolute;
      display:none;
      width:100%;
      top:63px;
      right:0;
      z-index:20;
      background:$orange;

      li {
        display:block;
        width:100%;
        padding:15px;
        box-sizing:border-box;
        text-align:center;
        background:$orange;
        border-top:1px solid lighten($orange, 20%);
        transition: background .8s;

        &:hover {
          background:darken($orange, 10%);
        }        
      }
    }
  }  
}

/* end header CSS */


/* start footer CSS */

footer {
	display: flex;
	width:100%;	
	background: $greyBG;

	div {
		width:33.33%;
		padding:5%;
	}

	.footerNav {
		display: flex;
		justify-content: space-between;

		ul {
			li {
				a {
					color: $greyText;
					text-decoration:none;
					line-height:1.8em;
					transition: color .8s;

					&:hover {
						color: $orange;
					}
				}
			}
		}	
	}

	.footerSocialMedia {
		padding:5% 0 5% 0%;
		line-height: 1.8em;
		text-align: left;
		
		a {
			color: $greyText;
			text-decoration: none;
			transition: color .8s;

			&:hover {
				color:$orange;
			}
		}

		img {
			height: 1.3em;
			vertical-align: top;
		}
	}

	.goodestCatFooterLogo {
		img {
			width:100%;
			max-width:100%;
			height:auto;
		}
	}
}

@media (max-width:1000px) {
	footer {		
		flex-wrap:wrap;

		div {
			width:50%;			
			box-sizing:border-box;
		}

		.footerNav {			
			order:1;			
		}

		.footerSocialMedia {
			width:100%;
			text-align:center;
			padding:0% 5% 5% 5%;			
			order:3;			

			img {
				height: 1.3em;
				vertical-align: top;
			}
		}

		.goodestCatFooterLogo {
			order:2;
			text-align:center;
			img {
				width:70%;
				max-width:70%;
			}
		}
	}
}

@media (max-width:650px) {
	footer {		
		display: block;
		flex-wrap:wrap;

		div {
			width:100%;			
			box-sizing:border-box;
		}

		.footerNav {
			display:block;			
			order:1;

			ul {
				display:flex;
				flex-wrap:wrap;
				justify-content:center;
				margin-bottom:20px;				

				li {
					display:inline;
					padding:0 2%;
					white-space: nowrap;
					//margin-right:25px;
				}
			}			
		}

		.footerSocialMedia {
			width:100%;			
			text-align:center;
			margin:0;
			padding:0% 5% 5% 0%;
			order:2;			

			img {
				height: 1.3em;
				vertical-align: top;
			}
		}

		.goodestCatFooterLogo {
			order:2;
			text-align:center;
			img {
				width:40%;
				max-width:40%;
			}
		}
	}
}

@media (max-width:530px) {
	footer {						
		.footerNav {			
			ul {				
				margin-bottom:0px;				

				li {
					display:inline;
					padding:0 2%;
					white-space: nowrap;					
				}
			}			
		}		
	}
}

/* end footer CSS */

/* start registrationPopup */

.registrationPopup {		
	.privacyLabel {		
		text-transform: none;
		font-weight:normal;
	}

	.agree {
		display:flex;
		align-items: first baseline;

		label {
			line-height:1.2em;
		}

		input {
			margin-right:15px;
		}
	}
}

.dataPrivacyPopup {
	position:absolute;
	top:0;
	width:80% !important;
	background:$white;
}	


@media (max-width:780px) {
	.popup-content {
		width:80%;		
	}	
}

@media (max-width:600px) {
	.registrationPopup {		
		.row {
			display:block;
			div {
				width:100%;
			}
		}

		.actions {
			text-align:center;
		}
		button {
			width:94%;
			margin:auto;
			padding:10px;
		}
	}
}

/* end registrationPopup */

